import React, { useState, useEffect } from 'react'
import styled from 'styled-components'


const MainDiv = styled.div`
  position: fixed;
  left: .0rem;
  width: 50px;
  z-index: 300;
  cursor: pointer;
  transform: scale(.55);
 
 &:before,
  &:after,
  div {
    background: #000000;
    content: "";
    display: block;
    height: 6px;
    border-radius: 3px;
    margin: 7px 0;
    transition: 0.5s;
  }

  /* Apply styles when menuActive is true */
  ${props =>
    props.menuactive === 'true' &&
    `
    &:before {
      transform: translateY(12px) rotate(135deg);
    }
    &:after {
      transform: translateY(-12px) rotate(-135deg);
    }
    div {
      transform: scale(0);
    }
  `}
`;

function HamburgerIcon({ menuActive, setMenuActive }) {
  
    // Function to toggle menuActive state when the icon is clicked
    const handleIconClick = () => {
      setMenuActive(!menuActive);
    };
  
    return (
      <MainDiv className="center" menuactive={menuActive ? 'true' : 'false'} onClick={handleIconClick}>
        <div></div>
      </MainDiv>
    );
  }
  

export default HamburgerIcon