"use client";

import { useEffect, useState } from 'react';
import { Inter } from 'next/font/google';
import { GlobalContextProvider } from '@/context/context';
import Menu from '@/components/menu-stuff/Menu';
import { NextIntlClientProvider } from 'next-intl';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import StyledComponentsRegistry from '../lib/registry';
import { Toaster } from 'react-hot-toast';
import LanguageToggle from '@/components/LanguageToggle';
import { BookingsBoxProvider } from '@/context/bookings-box-context';
import getRequestConfig from '../../i18n/request';
import './globals.css';

config.autoAddCss = false;

const inter = Inter({ subsets: ['latin'] });

export default function RootLayoutWrapper({ children, locale }) {
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    (async () => {
      const config = await getRequestConfig({ locale });
      setMessages(config.messages);
    })();
  }, [locale]);

  if (!messages) return null;

  return (
    <NextIntlClientProvider locale={locale} messages={messages}>
      <GlobalContextProvider>
        <BookingsBoxProvider>
          <Toaster
            containerStyle={{ zIndex: 100000000000000000 }}
            position="bottom-right"
            toastOptions={{ duration: 5000, style: { border: '1px solid black' } }}
          />
          <Menu />
          <LanguageToggle />
          <StyledComponentsRegistry>
            {children}
          </StyledComponentsRegistry>
        </BookingsBoxProvider>
      </GlobalContextProvider>
    </NextIntlClientProvider>
  );
}
