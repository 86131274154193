'use client'

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import HamburgerIcon from './HamburgerIcon';
import gsap from 'gsap';
import { useRouter } from '../../navigation';
// import { useRouter } from 'next/navigation';
import { usePathname } from 'next/navigation'
import { useGlobalContext } from '@/context/context';
import { useTranslations } from 'next-intl';

const MenuPanel = styled.div`
    z-index: 285;
    position: fixed;
    width: 16rem;
    background-color: #cbc8b7ff;
    border-right: 1px solid black;
    box-shadow: 4px 4px 4px rgba(0,0,0,.1);
    /* background-color: #b4b09cff; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%);
    gap: 1rem;
    padding: 0 1rem;
    p {
        font-size: 1.3rem;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            font-weight: 600;
        }
    }
    p:hover {
        /* font-style: italic; */
        /* text-decoration: underline; */
        /* color: white; */
    }
`

const AdminPortalDiv = styled.div`
    margin-top: 1.3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    h3 {
        font-weight: 800;
        font-size: 1.3rem;
        margin-bottom: .5rem;
    }
    p {
        font-size: 1.2rem;
    }
`

const LogoutBtn = styled.button`
    border: 1px solid black;
    width: fit-content;
    margin: 0 auto;
    padding: .7rem 2.25rem;
    margin-bottom: 1rem;   
    border-radius: 5px; 
    font-size: 1.1rem;
    font-weight: 600;
    &:hover {
        /* text-decoration: underline; */
    }
`


function Menu() {
    const { setMenuLoading, setUsersBooked, setActiveDay, setOtherSide, user, setUser, admin, setAdmin, otherSide } = useGlobalContext();
    const router = useRouter();
    const menuRef = useRef(null);
    const [menuActive, setMenuActive] = useState(false);
    const pathname = usePathname();
    const tMenu = useTranslations('Menu');

    useEffect(() => {
        if (!menuRef.current) return;
        if (menuActive) {
            gsap.to(menuRef.current, {
                transform: 'translateX(0%)',
                duration: 0.5,
                ease: 'power3.inOut',
                onStart: () => {
                    menuRef.current.style.display = 'flex'
                }
            });
        }
        if (!menuActive) {
            gsap.to(menuRef.current, {
                transform: 'translateX(-100%)',
                duration: 0.5,
                ease: 'power3.inOut',
                onComplete: () => {
                    if (menuRef.current !== null) {
                        // menuRef.current.style.display = 'none'
                    }
                }
            });
        }
    }, [menuActive])


    useEffect(() => {
        setMenuLoading(false);
        setMenuActive(false);
        if (pathname !== '/calendar') {
            setOtherSide(false);
            setUsersBooked([]); 
            setActiveDay('');
        }
      }, [pathname]);
      

    const handleCalendarPage = () => {
        // setOtherSide(false);
        router.push('/calendar');
    }
    const handleAddUserPage = () => {
        // setOtherSide(false);
        router.push('/admin/add-client');
    }
    const handleViewUsersPage = () => {
        // setOtherSide(false);
        router.push('/admin/view-clients');
    }
    const handleGeneratePeriodPage = () => {
        // setOtherSide(false);
        router.push('/admin/generate-period');
    }
    const handleUploadCSVPage = () => {
        // setOtherSide(false);
        router.push('/admin/upload-csv');
    }
    const handleViewRecordsPage = () => {
        // setOtherSide(false);
        router.push('/admin/view-records');
    }
    const handleEmailsPage = () => {
        // setOtherSide(false);
        router.push('/admin/share-emails');
    }
    const handleAdminControlPage = () => {
        // setOtherSide(false);
        router.push('/admin/admin-control');
    }
    const handleReservationsPage = () => {
        // setOtherSide(false);
        router.push('/admin/reservations');
    }
    const handleDeletePeriodPage = () => {
        // setOtherSide(false);
        router.push('/admin/delete-period');
    }

    const handleLogout = (event) => {
        setUser(null);
        setAdmin(null);
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        router.push('/');
    }

  return (
    <>
    {pathname !== '/' && (
        <>
        {(admin && (admin.adminRole === 'hotel' || admin.adminRole === 'events' || admin.adminRole === 'grat' || admin.adminRole === 'admin')) && (
            <>
        <HamburgerIcon menuActive={menuActive} setMenuActive={setMenuActive} />
        <MenuPanel ref={menuRef}>
            <div style={{flexGrow: 1}}></div>
            <p onClick={(e) => handleCalendarPage()}>{tMenu('Home')}</p>
            {/* <p onClick={(e) => handleProfilePage()}>Profile</p> */}
            <AdminPortalDiv>
                <h3>{tMenu('AdminPortal')}</h3>
                {admin && (admin.adminRole === 'hotel' && admin.viewRecords == true) && (
                    <p onClick={(e) => handleViewRecordsPage()}>{tMenu('ViewRecords')}</p>
                )}
                {(admin && (admin.adminRole === 'hotel' || admin.adminRole === 'events' || admin.adminRole === 'grat') && (
                    <p onClick={(e) => handleReservationsPage()}>{tMenu('HandleReservations')}</p>
                ))}
                {/* {(admin && (admin.adminRole === 'casino' || admin.adminRole === 'admin') && ( */}
                {(admin && admin.adminRole === 'admin') && (
                    <>
                    <p onClick={(e) => handleAddUserPage()}>{tMenu('AddClient')}</p>
                    <p onClick={(e) => handleViewUsersPage()}>{tMenu('ViewEditPromoCodes')}</p>
                    {/* <p onClick={(e) => handleGeneratePeriodPage()}>Generate period</p> */}
                    <p onClick={(e) => handleUploadCSVPage()}>{tMenu('UploadCSV')}</p>
                    <p onClick={(e) => handleViewRecordsPage()}>{tMenu('ViewRecords')}</p>
                    <p onClick={(e) => handleEmailsPage()}>{tMenu('SetEmails')}</p>
                    <p onClick={(e) => handleDeletePeriodPage()}>{tMenu('DeletePeriod')}</p>
                    <p onClick={(e) => handleReservationsPage()}>{tMenu('HandleReservations')}</p>
                    <p onClick={(e) => handleAdminControlPage()}>{tMenu('AdminControl')}</p>
                    </>
                )}
            </AdminPortalDiv>
            <div style={{flexGrow: 1}}></div>
            <LogoutBtn onClick={(e) => handleLogout(e)}>{tMenu('Logout')}</LogoutBtn>
        </MenuPanel>
        </>
        )}
        </>
    )}
    </>
  )
}

export default Menu