'use client'

import { useGlobalContext } from '@/context/context'
import React, { useTransition, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useRouter } from '../navigation.js'
import { usePathname } from 'next/navigation'
import {useLocale, useTranslations} from 'next-intl';
import gsap from 'gsap'

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    height: 2rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    padding: 0 .5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10000; //10
    /* width: 3rem; */
    opacity: 0;
    font-size: .8rem;
    /* display: none; */
`

function LanguageToggle() {
  const { languageToggle, setLanguageToggle, paymentInProgress } = useGlobalContext();
  const router = useRouter();
  const pathname = usePathname();
  const [isPending, startTransition] = useTransition();
  const locale = useLocale();
  const [visible, setVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    setVisible(true);
  }, [])

  useEffect(() => {
    if (visible) {
      gsap.to(containerRef.current, { opacity: .6, duration: .1 });
    }
  }, [visible])

  const handleToggleLanguage = () => {
    if (paymentInProgress) return;
    if (locale == 'en') {
        setLanguageToggle('fr');
        // router.replace('en', 'fr');
        const currentPathname = window.location.pathname;
        const newPathname = currentPathname.replace('/en', '/fr');
        window.location.href = newPathname;
        return;
      }
      else if (locale == 'fr') {
        // router.replace('http://localhost:3000/en');
        setLanguageToggle('en');
        const currentPathname = window.location.pathname;
        const newPathname = currentPathname.replace('/fr', '/en');
        window.location.href = newPathname;
        return;
    }
  }

  if (!visible) {
    return;
  }

  return (
    <Container ref={containerRef} onClick={(e) => handleToggleLanguage()}>
        {locale === 'en' && (
            <span>Français</span>
        )} 
        {locale === 'fr' && (
            <span>English</span>
        )} 
    </Container>
  )
}

export default LanguageToggle

// import {useLocale, useTranslations} from 'next-intl';
// import {locales} from '../config';
// import LocaleSwitcherSelect from './LocaleSwitcherSelect';

// export default function LocaleSwitcher() {
//   const t = useTranslations('LocaleSwitcher');
//   const locale = useLocale();

//   return (
//     <LocaleSwitcherSelect defaultValue={locale} label={t('label')}>
//       {locales.map((cur) => (
//         <option key={cur} value={cur}>
//           {t('locale', {locale: cur})}
//         </option>
//       ))}
//     </LocaleSwitcherSelect>
//   );
// }