// import {notFound} from "next/navigation";
// import {getRequestConfig} from 'next-intl/server';
 
// // Can be imported from a shared config
// const locales = ['en', 'fr'];
 
// export default getRequestConfig(async ({locale}) => {
//   const baseLocale = new Intl.Locale(locale).baseName;
//   // Validate that the incoming `locale` parameter is valid
//   if (!locales.includes(baseLocale)) notFound();
 
//   return {
//     messages: (await import(`./messages/${baseLocale}.json`)).default
//   };
// });

import { notFound } from 'next/navigation';

const locales = ['en', 'fr']; // Define supported locales

export default async function getRequestConfig({ locale }: { locale: string }) {
  const baseLocale = new Intl.Locale(locale).baseName;

  // Validate that the incoming `locale` parameter is valid
  if (!locales.includes(baseLocale)) {
    notFound();
  }

  return {
    messages: (await import(`./messages/${baseLocale}.json`)).default,
  };
}


// import {notFound} from 'next/navigation';
// import {getRequestConfig} from 'next-intl/server';
// import {locales} from './config';

// export default getRequestConfig(async ({locale}) => {
//   // Validate that the incoming `locale` parameter is valid
//   if (!locales.includes(locale)) notFound();

//   return {
//     messages: (
//       await (locale == 'en'
//         ? // When using Turbopack, this will enable HMR for `en`
//           import('../messages/en.json')
//         : import(`../messages/fr.json`))
//     ).default
//   };
// });